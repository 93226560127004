import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['wrapper']

  get specialOfferModal() {
    return document.querySelector('.js-special-offer-modal');
  }

  connect() {
    const { cookieName } = this.wrapperTarget.dataset;
    this.setCookie(cookieName);
  }

  togglePopup(e) {
    e.preventDefault();
    this.element.classList.add('reset-modal');
  }

  closeModal(e) {
    e.preventDefault();
    document.body.classList.remove('-is-open-modal');
    this.specialOfferModal.classList.remove('active');
  }

  setCookie(name, expires = 'Fri, 31 Dec 9999 23:59:59 GMT') {
    document.cookie = `${name}=true; expires=${expires}`
  }
}
